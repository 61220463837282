import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';
import { ContentfulCollection } from 'contentful';
import { useEffect, useState } from 'react';

import Banner, { BannerProps } from '@tb-core/components/composites/banner';
import ContentfulRichTextWithGaEvent from '@tb-core/components/simple/contentful-rich-text-with-ga-event';
import LazyIconClose from '@tb-core/components/simple/icons/lazy-icon-close';
import FlashMessage from '@tb-core/components/styled/flash-message';
import { isEmptyObject } from '@tb-core/helpers/object';
import { create256Hash } from '@tb-core/helpers/products/cart';
import { localStorage } from '@tb-core/helpers/storage';
import { ContentfulFlashMessage } from '@tb-core/types/contentful';

import styles from './styles.module.scss';

export interface ContentfulFlashBannerProps
    extends Omit<BannerProps, 'children'> {
    messages: ContentfulCollection<ContentfulFlashMessage>;
}

type DismissedBanners = Record<string, number>;

const TWO_WEEKS = 1000 * 60 * 60 * 24 * 14;
const STORAGE_KEY = 'dismissedBanners';

/**
 *
 * @param str
 * @returns kebab-case slug
 * remove any non-word characters, puctuiation, etc...
 * replace spaces, underscores, dashes with dash
 * remove leading or trailing dashes
 */
const toClassName = (str: string = '') =>
    str
        .trim()
        .toLowerCase()
        .replace(/[^\w\s-]/g, '') // remove any non-word / special characters
        .replace(/[\s_-]+/g, '-') // replace spaces, underscores, dashes with dash
        .replace(/^-+|-+$/g, ''); // remove leading or trailing dashes

const ContentfulFlashBanner = ({
    messages,
    ...props
}: ContentfulFlashBannerProps) => {
    const [
        dismissedBanners,
        setDismissedBanners
    ] = useState<DismissedBanners | null>(null);
    const now = Date.now();

    const filterExpired = (obj: DismissedBanners) => {
        const result: DismissedBanners = {};
        for (const [banner, dismissedAt] of Object.entries(obj)) {
            if (dismissedAt + TWO_WEEKS > now) {
                result[banner] = dismissedAt;
            }
        }
        return result;
    };

    const handleDismiss = (hash: string, tracking: Record<string, any>) => {
        const dismissed = {
            ...dismissedBanners,
            [hash]: now
        };

        setDismissedBanners(dismissed);
        localStorage.setItems({
            [STORAGE_KEY]: dismissed
        });
        track('App Sticky Banner > Dismiss', tracking);
    };

    // read storage, removed expired, set to state, udpate storage
    useEffect(() => {
        let dismissed = localStorage.getItems()[STORAGE_KEY] || {};
        if (!isEmptyObject(dismissed)) {
            dismissed = filterExpired(dismissed);
            localStorage.setItems({
                [STORAGE_KEY]: dismissed
            });
        }
        setDismissedBanners(dismissed);
    }, []);

    return (
        <>
            {messages && messages.items.length > 0 && (
                <Banner
                    {...props}
                    className={classNames(styles['flash-banner'], {
                        [styles.show]: dismissedBanners !== null
                    })}
                >
                    {messages.items.map(
                        ({ type = 'Maintenance', entryTitle, message }, i) => {
                            const hash = create256Hash(entryTitle);
                            return dismissedBanners &&
                                Object.keys(dismissedBanners).includes(hash) ? (
                                <></>
                            ) : (
                                <FlashMessage
                                    className={classNames(
                                        styles['flash-message'],
                                        styles[toClassName(type)]
                                    )}
                                    key={i}
                                >
                                    {/* @TODO Need to set `aria-labelledby` to some `id` of content, like a title. */}
                                    {/* https://www.w3.org/TR/wai-aria-practices/examples/landmarks/region.html */}
                                    {/* https://www.w3.org/TR/wai-aria-practices/examples/landmarks/banner.html */}
                                    <div>
                                        <ContentfulRichTextWithGaEvent
                                            gaDataLayerConfig={{
                                                'Analytics-Action':
                                                    'App Sticky Banner > ' +
                                                    entryTitle
                                            }}
                                            node={message.json}
                                        />
                                    </div>
                                    <LazyIconClose
                                        className={styles.dismiss}
                                        height="2em"
                                        onClick={() =>
                                            handleDismiss(hash, {
                                                banner: entryTitle
                                            })
                                        }
                                        width="2em"
                                    />
                                </FlashMessage>
                            );
                        }
                    )}
                </Banner>
            )}
        </>
    );
};

export default ContentfulFlashBanner;
