/**
 *
 * @returns type of device a user is using, on web
 */

export const getDeviceType = () => {
    if (typeof window !== 'undefined') {
        const agent = window.navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(agent)) {
            return 'tablet';
        }
        if (
            /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                agent
            )
        ) {
            return 'mobile';
        }
        return 'desktop';
    }
};
