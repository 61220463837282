import classNames from 'classnames';
import React from 'react';

import { DivProps } from '@tb-core/types/elements/standard.d';

import styles from './styles.module.scss';

export type BannerProps = DivProps;

const Banner = ({
    children,
    className,
    role = 'banner',
    ...props
}: BannerProps) => (
    <div
        {...props}
        className={classNames(styles.banner, className)}
        role={role}
    >
        {children}
    </div>
);

export default Banner;
