import * as amplitude from '@amplitude/analytics-browser';
import { webAttributionPlugin } from '@amplitude/plugin-web-attribution-browser';
import { amplitudeKey, webFixedVersion } from '@tb-core/helpers/next-env';

export const amplitudeInitialize = async (userId?: string) => {
    amplitude.add(webAttributionPlugin());
    amplitude.init(amplitudeKey, userId, {
        appVersion: webFixedVersion,
        disableCookies: true,
        logLevel: 2
    });

    return amplitude;
};
