import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useParam: () => [
    string | boolean,
    Dispatch<SetStateAction<string>>,
    boolean
] = () => {
    const [parameterValue, setParameterValue] = useState<string | boolean>('');
    const [key, setKey] = useState('');
    const [noParam, setNoParam] = useState(false);

    useEffect(() => {
        if (key) {
            const { search } = window.location;
            const keyLocation = key ? search.indexOf(key) : -1;

            if (search.length === 0 || keyLocation < 0) {
                setNoParam(true);
                return;
            }

            // Get a substring starting at the start of the key being searched
            const keyLocationSubstring = search.substr(keyLocation);
            // Get location of first '&' character past the key
            const locationOfAmp = keyLocationSubstring.indexOf('&');
            // Get the length of the key + the '=' character
            const lengthToStartOfKeyValue = key.length + 1;

            // If there are multiple params, stop at the first '&' character past the key
            if (
                locationOfAmp >= 0 &&
                keyLocationSubstring[lengthToStartOfKeyValue]
            ) {
                setParameterValue(
                    keyLocationSubstring.substring(
                        lengthToStartOfKeyValue,
                        locationOfAmp
                    )
                );
            } else if (keyLocationSubstring[lengthToStartOfKeyValue]) {
                setParameterValue(
                    keyLocationSubstring.substring(lengthToStartOfKeyValue)
                );
            } else if (keyLocationSubstring) {
                // When the key has no value ie /?logout
                setParameterValue(true);
            } else {
                setParameterValue('None');
            }
        }
    }, [key]);

    return [parameterValue, setKey, noParam];
};
