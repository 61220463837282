import { useEffect } from 'react';

import useDocumentReady from './use-document-ready';

interface ScrollToFragmentProps {
    trigger?: boolean;
    smooth?: boolean;
}

type UseScrollToFragment = (props: ScrollToFragmentProps) => void;

const useScrollToFragment: UseScrollToFragment = ({
    trigger = true,
    smooth = false
}) => {
    // let timeout: number;
    const [documentReady] = useDocumentReady();

    useEffect(() => {
        const scrollToFragment = () => {
            const { hash } = window.location;
            const target =
                hash && document.getElementById(hash?.replace('#', ''));

            if (target) {
                target.scrollIntoView({
                    ...(smooth && { behavior: 'smooth' }),
                    block: 'start',
                    inline: 'nearest'
                });
            }
        };

        if (!trigger || !documentReady) {
            return;
        }

        scrollToFragment();
        window.addEventListener('hashchange', scrollToFragment);

        return window.removeEventListener('hashchange', scrollToFragment);
    }, [trigger, documentReady]);
};

export default useScrollToFragment;
