import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';

import InlineSvgs from '@tb-core/components/composites/inline-svgs';
import { s3AssetPath } from '@tb-core/helpers/contentful/s3-asset-path';
import { assetPath } from '@tb-core/helpers/next-env';
import usePageContext from '@tb-core/hooks/use-page-context';

interface SvgPathAndFileName {
    fileName: string;
    path: string;
}
interface TopBodyProps {
    inlineSvgs?: Array<string | SvgPathAndFileName>;
}

const TopBody = ({ inlineSvgs }: TopBodyProps) => {
    const {
        content: { pageData }
    } = usePageContext();
    const icons = pageData.layout.icons;

    return useMemo(() => {
        return (
            <InlineSvgs icons={icons}>
                {inlineSvgs?.map((svg, i) => {
                    const path =
                        (svg as SvgPathAndFileName).path ||
                        '/_static/web/icons/';
                    const fileName =
                        (svg as SvgPathAndFileName).fileName || (svg as string);
                    return (
                        <SVG
                            key={i}
                            src={assetPath + s3AssetPath(path, fileName)}
                            uniquifyIDs={false}
                        />
                    );
                })}
            </InlineSvgs>
        );
    }, [assetPath]);
};

export default TopBody;
