import React from 'react';
import SVG from 'react-inlinesvg';

import withNextConfig from '@tb-core/components/hoc/with-next-config';
import { assetPath } from '@tb-core/helpers/next-env';
import { Props } from '@tb-core/types';

const InlineSvgs = ({ children, icons }: Props) => (
    <>
        {/* Load svg sprite inline. */}
        {icons && (
            <SVG
                src={`${assetPath}/_static/icons/${icons}`}
                uniquifyIDs={false}
            />
        )}
        {children}
    </>
);

export default withNextConfig(InlineSvgs);
