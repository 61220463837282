import { reset } from '@amplitude/analytics-browser';
import { useEffect } from 'react';

import StandardModal from '@tb-core/components/composites/standard-modal';
import BrandedButton from '@tb-core/components/styled/buttons/branded-button';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import usePageContext from '@tb-core/hooks/use-page-context';
import useUserContext from '@tb-core/hooks/use-user-context';
import { useParam } from '@tb-core/hooks/window/use-param';
import { postDeleteAuthCookies } from '@tb-core/providers/post-delete-auth-cookies';

import styles from './styles.module.scss';

const LoggedOutModal = () => {
    const id = 'logged-out-modal';
    const [param, setKey] = useParam();
    const { updateModals } = useLayoutActivityContext();
    const {
        content: {
            pageData: {
                layout: {
                    topicMetadataCollection: { items }
                }
            }
        }
    } = usePageContext();
    const { onLogout } = useUserContext();

    const { loggedOutCta, loggedOutMessage } =
        items.find(item => item.topic === 'Generic')?.data || {};

    useEffect(() => {
        // Search for logout in the path parameters
        setKey('logout');
    }, []);

    /** Close Modal and Remove '?logout' Url Param */
    const hideModal = () => {
        const url = window.location.href.replace(window.location.search, '');
        history.replaceState({}, '', url);
        updateModals({ [id]: undefined });
    };

    const modalData = {
        body: (
            <div className={styles['modal-message']} tabIndex={0}>
                {loggedOutMessage}
            </div>
        ),
        className: styles.modal,
        footer: (
            <BrandedButton
                className={styles['modal-button']}
                onClick={hideModal}
            >
                {loggedOutCta}
            </BrandedButton>
        )
    };

    useEffect(() => {
        // Open the modal when the ?logout parameter is found in the path
        if (param === true) {
            // delete token from sesion storage
            window.sessionStorage.removeItem('token');
            // delete securee cookies w API call
            postDeleteAuthCookies()
                .then(() => {
                    onLogout();
                    updateModals({
                        [id]: {}
                    });
                    reset(); // amplitude reset function
                })
                .catch(e => {
                    console.error('Error logging out', e);
                });
        }
    }, [param]);

    return (
        <StandardModal {...modalData} id={id} role="alert" showHeader={false} />
    );
};

export default LoggedOutModal;
