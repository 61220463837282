import { identify, Identify, track } from '@amplitude/analytics-browser';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import LoggedOutModal from '@tb-core/components/container/logged-out-modal';
import StoreNeededModal from '@tb-core/components/container/store-needed-modal';
import CartTooltipProvider from '@tb-core/components/context/cart-tooltip';
import LayoutActivityProvider from '@tb-core/components/context/layout-activity';
import StoreProvider from '@tb-core/components/context/store';
import UserProvider from '@tb-core/components/context/user';
import ContentfulFlashBanner from '@tb-core/components/styled/contentful-flash-banner';
import {
    getOptimizelyExperiments,
    optimizelyClient
} from '@tb-core/helpers/analytics/optimizely';
import { parseJwt } from '@tb-core/helpers/client/auth';
import { getDecodedCookie } from '@tb-core/helpers/cookies';
import { getDeviceType } from '@tb-core/helpers/get-device-type';
import { isEmptyObject } from '@tb-core/helpers/object';
import sessionStorage, {
    optimizelyGuestUserId
} from '@tb-core/helpers/storage';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import usePageContext from '@tb-core/hooks/use-page-context';
import useScrollToFragment from '@tb-core/hooks/window/use-scroll-to-fragment';

import styles from './styles.module.scss';
export interface WebpageLayoutProps {
    regions: {
        bottomBody?: ReactNode;
        footer?: ReactNode;
        head?: ReactNode;
        header?: ReactNode;
        leftAside?: ReactNode;
        main?: ReactNode;
        rightAside?: ReactNode;
        topBody?: ReactNode;
    };
}

const MainContentSection = ({ children }: { children: ReactNode }) => {
    const { activeLoyalty } = useLayoutActivityContext();
    const centerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (activeLoyalty && centerRef.current) {
            centerRef.current.scrollTop = 0;
            track('Clicked Rewards Icon', {});
        }
    }, [activeLoyalty]);

    return (
        <div ref={centerRef} id="scrollContainer" className={styles.center}>
            {children}
        </div>
    );
};

const WebpageLayout = ({
    regions: {
        bottomBody,
        footer,
        head,
        header,
        leftAside,
        main,
        rightAside,
        topBody
    }
}: WebpageLayoutProps) => {
    const {
        content: { pageData },
        storeData
    } = usePageContext();
    const [ready, setReady] = useState(false);
    const { flashMessageCollection, pageControls } = pageData?.layout;

    const customerIdPromise = async () => {
        let customer = '';
        const token = sessionStorage.getItems().token || {};
        if (token?.access_token) {
            const parsedToken = parseJwt(token.access_token);
            customer = parsedToken.sub;
        } else if (getDecodedCookie('access_token')) {
            const parsedToken = parseJwt(getDecodedCookie('access_token'));
            customer = parsedToken.cid;
        } else {
            const guestIdFromStorage = optimizelyGuestUserId();
            customer = guestIdFromStorage
                ? guestIdFromStorage
                : `guest_user-${uuid()}`;
        }

        return {
            attributes: {
                device_type: getDeviceType(),
                is_logged_in: !isEmptyObject(token),
                user_id: customer
            },
            id: customer
        };
    };

    optimizelyClient.onReady().then(() => {
        const experiments = getOptimizelyExperiments();

        if (experiments?.length) {
            for (const experiment of experiments) {
                const { experimentKey, variationKey } = experiment;

                if (experimentKey && variationKey) {
                    const identifyObj = new Identify();

                    identifyObj.set(
                        '[Optimizely] experiment_key:',
                        experimentKey
                    );
                    identifyObj.set(
                        '[Optimizely] variation_key:',
                        variationKey
                    );

                    identify(identifyObj);
                }
            }
        }

        setReady(true);
    });

    useScrollToFragment({ smooth: true, trigger: ready });

    // When optimizely ready, render. We want to parse the token. Will it be session or cookie here?
    return (
        <>
            <OptimizelyProvider
                optimizely={optimizelyClient}
                user={customerIdPromise()}
            >
                <CartTooltipProvider>
                    <LayoutActivityProvider>
                        <UserProvider
                            enabled={pageControls?.indexOf('User') >= 0}
                        >
                            <LoggedOutModal />
                            <StoreProvider initialStore={storeData}>
                                <>
                                    {head}
                                    {topBody}
                                    <div className={styles['entire-screen']}>
                                        {header && (
                                            <ContentfulFlashBanner
                                                messages={
                                                    flashMessageCollection
                                                }
                                            />
                                        )}
                                        <div
                                            className={
                                                styles['horizontal-flex']
                                            }
                                        >
                                            {leftAside}
                                            <div
                                                className={
                                                    styles['vertical-flex']
                                                }
                                            >
                                                {header}
                                                <MainContentSection>
                                                    {main}
                                                    {rightAside}
                                                    {footer}
                                                </MainContentSection>
                                            </div>
                                        </div>
                                    </div>

                                    {bottomBody}
                                    <StoreNeededModal />
                                </>
                            </StoreProvider>
                        </UserProvider>
                    </LayoutActivityProvider>
                </CartTooltipProvider>
            </OptimizelyProvider>
        </>
    );
};

export default WebpageLayout;
