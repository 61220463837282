import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import ContentfulRichText, {
    ContentfulRichTextProps
} from '@tb-core/components/simple/contentful-rich-text';
import Image from '@tb-core/components/styled/image';
import { ContentfulRichLinkProps } from '@tb-core/helpers/adapters/contentful/rich-text-props-adapter';
import { GaDataLayerConfig } from '@tb-core/helpers/analytics/google';
import mapLinkedEntries from '@tb-core/helpers/contentful/map-linked-entries';
import { interpolate } from '@tb-core/helpers/interpolate';
import { JsxChildren, RealObject } from '@tb-core/types';

interface ContentfulRichTextWithGaEventProps extends ContentfulRichTextProps {
    gaDataLayerConfig: GaDataLayerConfig;
    linkedEntries?: ContentfulRichLinkProps;
    storeId?: string;
}

export interface HyperLinkProps extends Options {
    content: RealObject[];
    data: RealObject;
}

const ContentfulRichTextWithGaEvent = ({
    linkedEntries,
    node,
    gaDataLayerConfig,
    renderOptions,
    storeId
}: ContentfulRichTextWithGaEventProps) => {
    let linkedEntryMap: Record<string, any>;
    const options: Options = {
        renderNode: {
            [INLINES.HYPERLINK]: ({
                data,
                content
            }: HyperLinkProps): JsxChildren => {
                const { uri } = data;
                const { value = '' } = content[0];
                return (
                    <SafeAnchorWithGaEvent
                        gaDataLayerConfig={{
                            ...gaDataLayerConfig,
                            'Analytics-Value': value
                        }}
                        href={storeId ? interpolate(uri, { storeId }) : uri}
                    >
                        {value}
                    </SafeAnchorWithGaEvent>
                );
            },
            [INLINES.EMBEDDED_ENTRY]: node => {
                linkedEntryMap =
                    linkedEntryMap || mapLinkedEntries(linkedEntries);
                // find the entry in the entryMap by ID
                const entry =
                    linkedEntryMap.entryMap.get(node.data.target.sys.id) || {};

                if (entry.__typename === 'Image') {
                    return <Image {...entry} />;
                }
            }
        }
    };

    if (renderOptions?.renderText) {
        options.renderText = renderOptions.renderText;
    }

    return <ContentfulRichText node={node} renderOptions={options} />;
};

export default ContentfulRichTextWithGaEvent;
