import {
    documentToReactComponents,
    Options
} from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';

// const _options = {
//     renderMark: {
//         [MARKS.BOLD]: (node)
//     }
//     renderNode: {
//         [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
//             console.log('node', node);
//             return <p>{children}</p>;
//         }
//     },
//     renderText: (text: any) => <>{console.log('text', text)}</>
// };

export interface ContentfulRichTextProps {
    node: Document;
    // See the following for usage of `renderOptions`:
    // @dependency -> @contentful/rich-text-types
    // @install -> npm i @contentful/rich-text-types
    // @reference -> https://www.npmjs.com/package/@contentful/rich-text-react-renderer
    renderOptions?: Options;
}

const ContentfulRichText = ({
    node,
    renderOptions
}: ContentfulRichTextProps) => (
    <>{documentToReactComponents(node, renderOptions)}</>
);

export default ContentfulRichText;
