import classNames from 'classnames';

import { DivProps } from '@tb-core/types/elements/standard.d';

import styles from './styles.module.scss';

export type FlashMessageProps = DivProps;

/**
 * A container with a displayed flash message.
 * @param {content} The RichText content to display in the message container.
 */
const FlashMessage = ({ className, ...props }: FlashMessageProps) => (
    <div
        {...props}
        className={classNames(styles['flash-message'], className)}
    />
);

export default FlashMessage;
