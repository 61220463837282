import { ContentfulRichLinkProps } from '@tb-core/helpers/adapters/contentful/rich-text-props-adapter';

const mapLinkedEntries = (links?: ContentfulRichLinkProps) => {
    const assetMap = new Map();
    const entryMap = new Map();

    // loop through the assets
    if (links?.assets?.block) {
        for (const asset of links.assets.block) {
            assetMap.set(asset.sys.id, asset);
        }
    }
    // loop through the block linked entries
    if (links?.entries?.block) {
        for (const entry of links.entries.block || {}) {
            entryMap.set(entry.sys.id, entry);
        }
    }
    // loop through the inline linked entries
    if (links?.entries?.inline) {
        for (const entry of links.entries.inline || {}) {
            entryMap.set(entry.sys.id, entry);
        }
    }

    return {
        assetMap,
        entryMap
    };
};

export default mapLinkedEntries;
