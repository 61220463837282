import { useEffect, useState } from 'react';

const useDocumentReady = () => {
    const [documentReady, setDocumentReady] = useState(false);

    useEffect(() => {
        const onLoad = () => setDocumentReady(true);

        // if already loaded
        if (document.readyState === 'complete') {
            onLoad();
        } else {
            window.addEventListener('load', onLoad, false);

            return () => window.removeEventListener('load', onLoad);
        }
    }, []);

    return [documentReady];
};

export default useDocumentReady;
